.mc-faqs-container {
  margin: 28px 0px;
  margin-left: 8px;

  .mc-accordeon-container {
    position: relative;
    margin: 14px 0px;
    border-bottom: 1px solid var(--Secundarios-Neutro-1, #040303);
    img {
      width: 100%;
      height: auto;
    }

    &.active {
  
      .mc-accordeon-toggle-icon {
        transform: rotate(-180deg) scale(1.7,1.3) !important;
        line-height: 32px;
        transform: scale(1,1.2);
      }
      .mc-accordeon-container__gradient_active {
        display: block;
      }
      .mc-accordeon-container__gradient {
        display: none;
      }
    }
  
    .mc-accordeon-toggle {
      display: block;
      position: relative;
      cursor: pointer;
      padding: 10px 0px;
      padding-bottom: 15px;
      padding-right: 58px;
    
      color: var(--Contenidos, #131112);
      font-size: 20px;
      font-style: normal;
      font-weight: 900;
      line-height: 22px;
      box-sizing: border-box;
  
      &:hover{
        opacity: 0.8;
      }
    }

    .mc-accordeon-container__gradient,
    .mc-accordeon-container__gradient_active {
      @media only screen and (min-width: 992px) {
        opacity: 0.05 !important;
        display: none !important;
      }
    }
    
    .mc-accordeon-toggle-icon {
      position: absolute;
      width: 28px;
      height: 32px;
      right: 10px;
      top: 6px;
      -webkit-transition: all .2s cubic-bezier(.77,0,.175,1);
      -moz-transition: all .2s cubic-bezier(.77,0,.175,1);
      -o-transition: all .2s cubic-bezier(.77,0,.175,1);
      transition: all .2s cubic-bezier(.77,0,.175,1);
      color: #1C1B1F;
      font-weight: 400;
      text-align: center;
      font-size: 24px;
      line-height: 28px;
    }
    
    .mc-accordeon-content {
      padding-bottom: 22px;
      padding-top: 0px;
      padding-right: 10px;
      padding-left: 0px;
      text-align: left;
      color: #121212;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }

    &__gradient_active {
      display: none;
    }
  }

  &.mc-faqs-container-type-1 {

  }
  &.mc-faqs-container-type-2 {

  }
}