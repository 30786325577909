.mc-awards-container {
  margin-top: 50px;
  .mc-awards-container__items {
    display: block;
    margin-bottom: 0px;

    @media only screen and (min-width: 992px) {
      text-align: center;
    }

    .mc-awards-container__items--item {
      display: block;
      width: 100%;
      margin-bottom: 18px;
      position: relative;
      background-color: transparent ;
      box-sizing: border-box;
      padding: 12px 14px;
      padding-bottom: 2px;

      -webkit-transition: all .2s cubic-bezier(.77,0,.175,1);
      -moz-transition: all .2s cubic-bezier(.77,0,.175,1);
      -o-transition: all .2s cubic-bezier(.77,0,.175,1);
      transition: all .2s cubic-bezier(.77,0,.175,1);

      &:hover {
        @media only screen and (min-width: 992px) {
          background: #fff;
          border-radius: 20px;
          box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.3);
        }
      }

      &__image {
        display: block;
        background-color: #fff;
        margin-bottom: 20px;
        padding: 15px 0;
        
        img {
          display: block;
          margin: 0 auto;
          width: 80px;
          height: auto;
        }
      }
      &__info {
        display: block;
        width: 100%;
        @media only screen and (min-width: 992px) {
          text-align: left;
        }
      }
      .mc-awards-container__items--item__labels {
        z-index: 2;
      }
      &__name {
        color: #040303;
        font-size: 22px;
        font-style: normal;
        font-weight: 900;
        line-height: 26px;
        font-family: 'Myriad Pro Bold';
        margin-bottom: 10px;
      }
      &__price {
        background-color: #fff;
        text-align: center;
        padding: 4px 0px;
        border-radius: 16px;
        box-shadow: 0px 6px 14px 0px rgba(35, 31, 32, 0.30);
        color: #131112;
        font-family: "Myriad Pro";
        font-size: 38px;
        font-style: normal;
        font-weight: 900;
        line-height: 44px;
        font-family: 'Myriad Pro Bold';
      }
      &__actions {
        .mc-button-container__status--inactive {
          border: 1px solid #3A3A3A;
          background-color: #D8D8D8;
          color: #3A3A3A;
          opacity: 1;

          .mc-icon {
            transform: scale(1);
            margin-bottom: -3px;
            position: relative;
            top: -2px;
          }
        } 
      }

      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;

        a {
          color: $colorText;
        }
      }

      &.type-1 {
        height: auto;
      }
      &.type-2 {
        height: auto;

        .mc-awards-container__items--item__info {
          height: 100px;
          margin-bottom: 18px;
          position: relative;

          .mc-awards-container__items--item__image {
            width: 100px;
            height: 100%;
            position: absolute;
            left: 0px;
            top: 0px;
            z-index: 1;
            padding: 0px;
            box-sizing: border-box;
            background-position: center center !important;
            background-repeat: no-repeat !important;
            background-size: 70% !important;
          }
          .mc-awards-container__items--item__info {
            height: 100%;
            padding-left: 116px;
            box-sizing: border-box;

            .mc-awards-container__items--item__price {
              position: absolute;
              right: 0px;
              bottom: 0px;
              background-color: $primaryColor;
              color: #fff;
              font-size: 32px;
              font-style: normal;
              font-weight: 700;
              line-height: 44px;
              left: 116px;
              text-align: center;
            }
          }
        }

        p {
          text-align: center;
        }
      }
      &.type-3 {
        height: auto;
        padding: 10px;

        .mc-awards-container__items--item__info {
          height: 128px;
          position: relative;
          box-sizing: border-box;
          padding: 20px;
          padding-left: 122px;
          @media (max-width: 768px) {
            height: auto;
           
          }

          .mc-awards-container__items--item__image {
            width: 100px;
            height: 100px;
            position: absolute;
            left: 0px;
            top: 14px;
            z-index: 1;
            padding: 0px;
            box-sizing: border-box;
            background-position: center center !important;
            background-repeat: no-repeat !important;
            background-size: 94% !important;
            border-radius: 30px;
            overflow: hidden;
          }
          .mc-awards-container__items--item__labels {
            height: 100%;
            box-sizing: border-box;


          }
        }

        .mc-awards-container__items--item__actions {
          padding: 0px;
          padding-top: 0px;
          box-sizing: border-box;

          p {
            margin-top: 0px;
            text-align: center;

            a {
              text-decoration: none;
              color: #040303;
              text-align: center;
              font-family: "Myriad Pro";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-decoration: underline;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      &.type-4 {
        height: auto;
        background-color: transparent !important;
        box-sizing: border-box;
        padding-left: 60px;
        position: relative;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 0px;

        .mc-awards-container__items--item__info {
          background-color: #ededed;
          box-sizing: border-box;
          padding: 14px;
          padding-bottom: 10px;
          padding-left: 55px;

          .mc-awards-container__items--item__image {
            position: absolute;
            width: 100px;
            height: 100px;
            box-sizing: border-box;
            border-radius: 200px;
            top: 0px;
            bottom: 0px;
            margin: auto;
            left: 0px;
            z-index: 2;
            background: #fff;
            border: 1px solid #000;
            -webkit-box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.2);
            -moz-box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.2);
            box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.2);

            span {
              position: absolute;
              left: 12px;
              top: 12px;
              bottom: 12px;
              right: 12px;
              z-index: 1;
              border-radius: 100px;
              background-position: center center !important;
              background-repeat: no-repeat !important;
              background-size: cover !important;
            }
          }

          .mc-awards-container__items--item__name {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 6px;
          }

          .mc-awards-container__items--item__price {
            font-size: 24px;
            font-style: normal;
            font-weight: 900;
            line-height: 28px;
            margin-bottom: 10px;
          }

          a {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            text-decoration-line: underline;
          }
        }
      }
    }
  }

  .mc-awards-container__actions {
    background-color: transparent;
    text-align: center;
    margin: 18px auto;
    max-width: 390px;
    border: 1px solid $primaryColorText;
    border-radius: 100px;
    margin-top: 38px;

    a {
      color: $primaryColorText;
      text-decoration: none;
      display: block;
      padding: 10px 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 20px;
      
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }

  }

  &.mc-awards-column-1 {
    .mc-awards-container__items {
      @media only screen and (min-width: 992px) {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
      }

      &--item {
        @media only screen and (min-width: 992px) {
          width: 100%;
          display: inline-block;
          vertical-align: top;
          margin: 0 auto;
          margin-bottom: 28px;
        }
      }

    }
  }

  &.mc-awards-column-2 {
    .mc-awards-container__items {
      @media only screen and (min-width: 992px) {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
      }

      &--item {
        @media only screen and (min-width: 992px) {
          width: 47%;
          display: inline-block;
          vertical-align: top;
          margin: 0 1.5%;
          margin-bottom: 28px;
        }
      }

    }
  }

  &.mc-awards-column-3 {
    .mc-awards-container__items {

      @media only screen and (min-width: 992px) {
        margin-left: -1.5%;
        margin-right: -1.5%;
      }

      &--item {
        @media only screen and (min-width: 992px) {
          width: 30.3%;
          display: inline-block;
          vertical-align: top;
          margin: 0 1.5%;
          margin-bottom: 28px;
        }
      }
      
    }
  }
}

.mc-awards-container__steper {
  margin: 28px 0;
  padding-top: 60px;
  .slick-slider {
    .slick-track,
    .slick-list {
      .mc-awards-container__items {
        .mc-awards-container__items--item {
          .mc-custom-modal {
            &.slow_ani,
            .slow_ani {
              -webkit-transition: all .1s cubic-bezier(.77,0,.175,1) !important;
              -moz-transition: all .1s cubic-bezier(.77,0,.175,1) !important;
              -o-transition: all .1s cubic-bezier(.77,0,.175,1) !important;
              transition: all .1s cubic-bezier(.77,0,.175,1) !important;
            }
          }
        }
      }
    }

    .slick-dots {
      position: absolute;
      top: -60px;
      bottom: auto;
      background-color: rgb(245, 245, 245);
      height: 38px;

      @media only screen and (min-width: 992px) {
        right: 0px;
        left: 0px;
        width: 350px;
        margin: auto;
      }
      
      li {
        width: 50%;
        height: 100%;
        float: left;
        margin: 0px !important;
        text-align: center;
        font-weight: 600;
        line-height: 4px;
        -webkit-transition: all .3s cubic-bezier(.77,0,.175,1) !important;
        -moz-transition: all .3s cubic-bezier(.77,0,.175,1) !important;
        -o-transition: all .3s cubic-bezier(.77,0,.175,1) !important;
        transition: all .3s cubic-bezier(.77,0,.175,1) !important;

        &.slick-active {
          background-color: $primaryColor;
          color: #fff;
        }

        @media only screen and (min-width: 992px) {
          span {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0px;
            top: 0px;
            z-index: 1;
          }
        }
      }
    }
  }
}

.mc-awards-container__selector {
  position: relative;
  margin: 0 auto;
  margin-bottom: 32px;
  max-width: 392px;

  @media only screen and (min-width: 992px) {
    margin-bottom: 60px;
  }

  select {
    display: block;
    font-size: 16px;
    font-family: 'Verdana', sans-serif;
    font-weight: 400;
    color: #444;
    line-height: 1.3;
    padding: 8px 12px;
    width: 100%;
    max-width: 100%; 
    box-sizing: border-box;
    margin: 20px auto;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.03);
    border-radius: .3em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNyAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Imljb24va2V5Ym9hcmRfYXJyb3dfZG93biI+CjxtYXNrIGlkPSJtYXNrMF8xMDMzXzMyMCIgc3R5bGU9Im1hc2stdHlwZTphbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMCIgeT0iMCIgd2lkdGg9IjE3IiBoZWlnaHQ9IjE2Ij4KPHJlY3QgaWQ9IkJvdW5kaW5nIGJveCIgeD0iMC41IiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9IiNEOUQ5RDkiLz4KPC9tYXNrPgo8ZyBtYXNrPSJ1cmwoI21hc2swXzEwMzNfMzIwKSI+CjxwYXRoIGlkPSJrZXlib2FyZF9hcnJvd19kb3duIiBkPSJNOC40OTk4NyA5Ljk4MzMzQzguNDEwOTggOS45ODMzMyA4LjMyNzY1IDkuOTY5NDUgOC4yNDk4NyA5Ljk0MTY3QzguMTcyMDkgOS45MTM4OSA4LjA5OTg3IDkuODY2NjcgOC4wMzMyIDkuOEw0Ljk2NjU0IDYuNzMzMzRDNC44NDQzMSA2LjYxMTExIDQuNzgzMiA2LjQ1NTU2IDQuNzgzMiA2LjI2NjY3QzQuNzgzMiA2LjA3Nzc4IDQuODQ0MzEgNS45MjIyMiA0Ljk2NjU0IDUuOEM1LjA4ODc2IDUuNjc3NzggNS4yNDQzMSA1LjYxNjY3IDUuNDMzMiA1LjYxNjY3QzUuNjIyMDkgNS42MTY2NyA1Ljc3NzY1IDUuNjc3NzggNS44OTk4NyA1LjhMOC40OTk4NyA4LjRMMTEuMDk5OSA1LjhDMTEuMjIyMSA1LjY3Nzc4IDExLjM3NzYgNS42MTY2NyAxMS41NjY1IDUuNjE2NjdDMTEuNzU1NCA1LjYxNjY3IDExLjkxMSA1LjY3Nzc4IDEyLjAzMzIgNS44QzEyLjE1NTQgNS45MjIyMiAxMi4yMTY1IDYuMDc3NzggMTIuMjE2NSA2LjI2NjY3QzEyLjIxNjUgNi40NTU1NiAxMi4xNTU0IDYuNjExMTEgMTIuMDMzMiA2LjczMzMzTDguOTY2NTQgOS44QzguODk5ODcgOS44NjY2NyA4LjgyNzY1IDkuOTEzODkgOC43NDk4NyA5Ljk0MTY3QzguNjcyMDkgOS45Njk0NSA4LjU4ODc2IDkuOTgzMzMgOC40OTk4NyA5Ljk4MzMzWiIgZmlsbD0iIzFDMUIxRiIvPgo8L2c+CjwvZz4KPC9zdmc+Cg=='), linear-gradient(to bottom, #ffffff 0%,#fff 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 8px top 50%, 0 0;
    background-size: 22px auto, 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px;

    &::-ms-expand {
      display: none;
    }
    &:hover {
      border-color: #888;
    }
    &:focus {
      border-color: #aaa;
      box-shadow: 0 0 1px 2px rgba(59, 153, 252, .7);
      box-shadow: 0 0 0 2px -moz-mac-focusring;
      color: #222; 
      outline: none;
    }

    option {
      font-weight: normal;

      &[selected] {
        background-color: orange;
      }
    }
  }
}