.mc-custom-modal {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 999;
  visibility: hidden;
  opacity: 0;

  &__overlay {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 1;
    background: rgba(0, 0, 0, 0.80);
    backdrop-filter: blur(1px);
  }

  &__center {
    width: 364px;
    height: 100%;
    margin: 0 auto;
    max-width: 92%;
    z-index: 1;

    &--window {
      position: relative;      
      visibility: hidden;
      opacity: 0;
      transform: scale(0);      
      box-sizing: border-box;
      padding: 16px 22px;
      box-shadow: 0px 14px 44px 0px #000000;
      -webkit-box-shadow: 0px 14px 44px 0px #000000;
      -moz-box-shadow: 0px 14px 44px 0px #000000;

      border-radius: var(--XL, 32px);
      border: 2px solid var(--Secundarios-Neutro-8, #F0F0F0);
      background: var(--Neutro-9, #FFF);


      &__redemtion {
        position: relative;
        z-index: 1;

        .mc-custom-modal__center--window__imager {
          width: 120px;
          height: auto;
          display: block;
          margin: 0 auto;
          margin-top: 0px;
        }
        .mc-custom-modal__center--window__head {
          color: #0F0F0F;
          text-align: center;
          font-family: 'Myriad Pro';
          font-size: 40px;
          font-style: normal;
          font-weight: 600;
          line-height: 44px;
          margin-top: 14px;
          margin-bottom: 0px;

          @media only screen and (min-width: 992px) {
            margin-top: 0px;
          }
        }
        .mc-custom-modal__center--window__title {
          color: #0F0F0F;
          text-align: center;
          font-size: 28px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
          margin-top: 5px;

          strong {
            font-family: 'Myriad Pro Bold';
            color: #ED1C27 !important;
          }
        }
        .mc-custom-modal__center--window__auth {
          position: relative;
          display: none;
          h3 {
            color: #131112;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 900;
            line-height: 22px;
            font-family: 'Myriad Pro Bold';

            @media only screen and (min-width: 992px) {
              margin: 8px 0px;
            }
          }
          p {
            color: #131112;
            text-align: center;
            font-family: "Myriad Pro";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
          }
          &--error {
            padding-left: 30px;
            position: relative;
            color: var(--Primario-Primario-oscuro, #C90C14);
            font-family: "Myriad Pro";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px; /* 137.5% */

            svg {
              position: absolute;
              left: 0px;
              width: 24px;
              top: 8px;
            }
          }
        }
        .mc-custom-modal__center--window__recapcha {
          position: relative;
          text-align: center;
          div {
            margin: 0 auto;
          }
        }
      }

      &__actions {
        .mc-button-container__primary {
          .mc-button-container__status--active {
            .mc-icon {
              width: 22px;
              display: inline-block;
              vertical-align: middle;
              position: relative;
              top: -2px;

              .icon-download {
                width: 20px;
                height: 20px;
                position: relative;
                display: block;
              }
              img {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }

      
      &__image {
        height: 80px;
        width: 90px;
        margin: 0 auto;
        display: block;
        margin-top: 20px;
        box-sizing: border-box;
        background-position: center center !important;
        background-repeat: no-repeat !important;
        background-size: 80% !important;
      }
      &__title {
        text-align: center;
        margin: 24px 0px;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        color: $colorText;
      }
      &__desc {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        margin: 18px 0;
      }

      &__body {
        &.active {
          height: 140px;
          position: relative;

          .mc-custom-modal__center--window__image {
            width: 118px;
            height: 99px;
            z-index: 1;
            position: absolute;
            left: 0px;
            top: 5px;
            margin: 0;
            background-size: 90% !important;
          }
          .mc-custom-modal__center--window__desc {
            text-align: left;
            left: 130px;
            position: absolute;
            top: -5px;
            right: 0px;
            div {
              color: $primaryColorText;
              font-size: 24px;
              font-style: normal;
              font-weight: 900 !important;
              line-height: 28px;
              margin-bottom: 12px;
            }
          }
          .mc-custom-modal__center--window__title {
            text-align: left;
            left: 130px;
            position: absolute;
            top: 46px;
            right: 0px;
            margin: 0px;

            background-color: #fff;
            text-align: center;
            padding: 7px 0px;
            color: $primaryColorText;
            box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.1);
            -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.1);
            -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.1);
            border-radius: 100px;
            font-size: 32px;
            font-style: normal;
            font-weight: 900;
            line-height: 34px;
            border: 1px solid #7B7B7B;
          }
          a {
            font-size: 16px;
            font-style: normal;
            font-weight: 350;
            line-height: 20px;
            position: absolute;
            left: 0px;
            right: 0px;
            bottom: 5px;
            text-align: center;
            text-decoration: none;
            color: $primaryColorText;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      &__actions {
        margin: 20px 0px; 

        .mc-button-container__secondary {
          .mc-button-container__status--active {
            background-color: #fff;
            border: 1px solid #040303;
            color: #040303;

            &:hover {
              opacity: 0.6;
            }
          }
        }

        @media only screen and (min-width: 992px) {
          margin-top: 30px;
        }

        .mc-button-container {
          margin: 10px 0px;

          button {
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            line-height: 20px; /* 125% */
          }
        }
        
        &.redemtion {
          @media only screen and (min-width: 992px) {
            margin-top: 10px;
            margin-bottom: 10px;

            .mc-button-container {
              display: inline-block;
              vertical-align: middle;
              width: 48%;

              .icon-secure {
                svg {
                  path {
                    stroke: #fff;
                  }
                }
              }

              .mc-button-container__status--inactive  {
                .icon-secure {
                  svg {
                    path {
                      stroke: #4D4D4D;
                    }
                  }
                }
              }

              &:nth-child(1) {
                margin-right: 2%;
              }
              &:nth-child(2) {
                margin-left: 2%;
              }
            }
          }
          .icon-download {
            svg {
              fill: #fff;

              path {
                stroke: #fff;
              }
            }
          }
        }
        
      }
      &__terms {
        text-align: justify;
        margin-bottom:0px;
        a, .custom-a {
          color: #040303;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          display: block;
          text-decoration: none;

          max-height: 230px;
          overflow: auto; 
          padding: 14px 4px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__close {
    position: absolute;
    right: 18px;
    top: 16px;
    cursor: pointer;
    z-index: 2;
    
    &:hover {
      opacity: 0.6;
    }
  }

  &__opened {
    visibility: visible;
    opacity: 1;
    transform: scale(1);

    .mc-custom-modal__center--window {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }
  }

  &.globalModal {
    .mc-custom-modal__center--window  {
      background-color: #F5EFE5;
      .mc-custom-modal__close {
        z-index: 2;
      }
      .mc-custom-modal__center--window__title {
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: 24px;
        color: #3A3A3A;
      }
      .mc-custom-modal__center--window__desc {
        font-size: 16px;
        font-style: normal;
        font-weight: 350;
        line-height: 20px;
      }
      .mc-custom-modal__center--window__image {
        width: 90px;
        height: 140px;
        margin-top: 0px;
        margin-top: -75px;
        background-size: 100% !important;
      }
    }
  }

  &.look-preferente {
    .mc-custom-modal__center--window {
      background-color: #dfe9fc; 
    }

    &.globalModal {
      .mc-custom-modal__center--window {
        background-color: #0D3474;
        .mc-custom-modal__close {
          .icon-close {
            svg {
              path {
                fill: #fff;
              }
            }
          }
        }
        .mc-custom-modal__center--window__title,
        .mc-custom-modal__center--window__desc {
          color: #fff;
        }
        .mc-custom-modal__center--window__actions {
          .mc-button-container__status--active {
            background-color: #fff;
            color: #0D3474;

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }

  &.modal-push {
    .mc-custom-modal__center--window {
      padding: 0px;
      background: #FFF2DA;
      .mc-custom-modal__close {
        z-index: 2 ;
      }
      .mc-custom-modal__center--window__body  {
        border-radius: 30px;
        overflow: hidden;
        .mc-custom-modal__center--window__imager {
          width: 100%;
          height: auto;
          background-color: #f8eee4;
        }
        .mc-custom-modal__center--window__title {
          padding:  0 16px;
          font-size: 24px;
          font-style: normal;
          font-weight: 900;
          line-height: 28px;
        }
        .mc-custom-modal__center--window__desc {
          padding:  0 16px;
          font-size: 16px;
          font-style: normal;
          font-weight: 800;
          line-height: 20px;
        }
      }
      .mc-custom-modal__center--window__actions {
        padding: 16px;
        padding-top: 0px;
      }
    }

    &.look-preferente {
      .mc-custom-modal__center--window {
        background-color: #dfe9fc !important;
      }
      .mc-custom-modal__center--window__imager {
        background-color: #f0f5fd !important;
      }
    }
  }

  &.modal-info {
    .mc-custom-modal__center--window__title {
      margin-top: 40px;
      color: var(--Contenidos, #131112);
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;

      strong {
        color: var(--Contenidos, #131112) !important;
        text-align: center !important;
        font-size: 24px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: 28px !important;
      }
    }
    .mc-custom-modal__center--window__head {
      color: var(--Contenidos, #131112);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
}