// Base
@import "./base/variables";
@import "./base/normalize";
@import "./base/fonts";

// Components
@import "./components/button";
@import "./components/awards";
@import "./components/allies";
@import "./components/profile";
@import "./components/modals";
@import "./components/loginForm";
@import "./components/accordeon";
@import "./components/progressBar";
@import "./components/progressBarDashboard";
@import "./components/progressCircle";
@import "./components/sliderRotator";
@import "./components/cardBox";
@import "./components/textImageBox";
@import "./components/banner";
@import "./components/alertMessage";
@import "./components/dashboard";
@import "./components/preloader";
@import "./components/goalCounter";
@import "./components/counterDown";
@import "./components/pagger";
@import "./components/sectionPremioMayor";
@import "./components/bannerInfo";
@import "./components/sectionAssistanceServices";
@import "./components/sectionCards";
@import "./components/stairSlider";
@import "./components/quiz";
@import "./components/davipuntos";
@import "./components/ImageSlider";
@import "./components/sliderPlanSection";
@import "./components/SectionFaqrsMayorAward";
@import "./components/sectionTYC";
@import "./components/sliderDavipuntosAwards";
@import "./components/splash";
@import "./components/postRedemtion";

// Layout
@import "./layout/container";
@import "./layout/columns";
@import "./layout/header";
@import "./layout/footer";

// Pages
@import "./pages/general";
@import "./pages/login";
@import "./pages/mechanic";
@import "./pages/progress";
@import "./pages/awards";
@import "./pages/alliances";
@import "./pages/redemtions";
@import "./pages/faqs";
@import "./pages/terms";
@import "./pages/notfound";
@import "./pages/mayorAward";

// general
.mc-app-fullheight {
  height: 100%;
}

.mc-app-rely {
  position: relative;
  display: table;
  height: 100%;
  width: 100%;
  z-index: 2;
  
  .mc-app-centry {
    position: relative;
    vertical-align: middle;
    display: table-cell;
    z-index: 1;
    &.bottom{
      vertical-align: bottom !important;
  
    }
    &.top{
      vertical-align: text-top !important;
  
    }
  }
}

.slow_ani {
  -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
  -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
  -o-transition: all .3s cubic-bezier(.77,0,.175,1);
  transition: all .3s cubic-bezier(.77,0,.175,1);
}

.font-20 {
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.fixed-scroll {
  background-color: #fff;

  .mc-stair-slider__carousel--labels__tooltip,
  .mc-stair-slider__carousel--labels__cta {
    @media only screen and (max-width: 992px) {
      margin-left: auto;
      margin-right: auto;
    } 
  }
  .mc-stair-slider__carousel--labels__tooltip {
    @media only screen and (min-width: 992px) {
      margin-bottom: 14px;

      .mc-icon {
        position: relative;
        top: -1px;
      }
    } 
  }

  .SectionCards {
    background-color: #fff;
    background: #fff !important;
    margin-bottom: 0px;
  }
  .scroll-content {
    background: #fff !important;
  }
  .sticky-title {
    padding-top: 90px !important;
    .line-title {
      &:before {
        display: none;
      }
    }
    h4 {
      margin-bottom: 16px !important;
    }
    p {
      color: var(--Contenidos, #131112) !important;
      font-family: "Myriad Pro" !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 22px !important;
      padding-right: 60px;
      margin-bottom: 14px !important;

      @media only screen and (max-width: 992px) {
        padding-right: 0px;
      } 

      strong {
        font-weight: bold;
      }
    }
  }
}
.mechanic-quiz {
  .mc-quiz {
    padding-top: 120px;
    padding-bottom: 90px;

    @media only screen and (max-width: 992px) {
      padding-top: 20px;
      padding-bottom: 50px;
    }
  }
}

.App {
  &.modalOpens {
    height: 750px;
    .mc-awards-container__steper {
      .slick-slider {
        .slick-track,
        .slick-list {
          position: fixed !important;
          left: 0px !important;
          top: 0px !important;
          right: 0px !important;
          bottom: 0px !important;
          width: auto !important;
          height: auto !important;
          z-index: 999;

          .mc-awards-container__items {
            .mc-awards-container__items--item {
              background-color: transparent !important;

              .mc-custom-modal__overlay {
                background-color: rgba(83, 83, 83, 1);
              }
              .mc-awards-container__items--item__image,
              .mc-awards-container__items--item__info {
                visibility: hidden !important;
              }
            }
          }
        }
      }
    }
    .mc-header-container {
      opacity: 0;
    }
    .mc-footer-container {
      display: none !important;
    }
  }

  &.noLoggedIn {
    .mc-header-container-type-1 {
      .mc-header-container__nav {
        @media only screen and (min-width: 992px) {
          right: 10px;
        }
      }
    }
    .mc-footer-container {
      @media only screen and (max-width: 992px) {
        padding-bottom: 0px;
      }
    }

  }

  .mc-section {
    display: block;
    margin: 40px 0px;

    h2 {
      color: #131112;
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
      max-width: 95%;
      margin: 26px auto;
      margin-bottom: 24px;

      @media only screen and (min-width: 992px) {        
        font-weight: 900;
        line-height: 50px;
        color: #0F0F0F;        
      }
    }

    &.isLight {
      h2 {
        color: $primaryColorText;
      }
    }
  }

  .mc-header-container-page-dashboard {
    .mc-header-container__menu {
      .mc-header-container__nav {
        display: none;
      }
    }
    .mc-header-container__submenu  {
      display: none !important;
    }
  }

  .mc-page.dashboard {
    padding-top: 60px;
    @media only screen and (max-width: 992px) {
      padding-top: 20px;
    }
  }
}

// Pages
.demo-section {
  margin: 20px 0px;
  display: block;
}

.davipuntos-container-app{
  background: linear-gradient(180deg, var(--Secundarios-Neutro-8, #F0F0F0) 0.13%, var(--Secundarios-Neutro-7, #B8B6B7) 189.28%);
}

.mc-awards-container__items--item__container {
  .mc-custom-modal__center {
    width: 550px;
    max-width: 94%;
  }
  .mc-custom-modal__center--window__scroll {
    max-height: 220px;
    overflow: auto;
    text-align: left;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    padding: 10px 18px;
  }
  .mc-custom-modal__center--window__title {
    font-size: 16px;
    text-align: left;
    padding-left: 200px;
    line-height: 20px;
    margin-top: 18px;
    strong {
      color: #0F0F0F !important;
      display: block;
      margin-left: 10px;
      font-size: 26px;
      margin-left: 0px;
      line-height: 30px;
      margin-bottom: 4px;
    }
  }
  .mc-custom-modal__center--window__imager {
    position: absolute;
    left: 62px;
  }
}

.newBenefitsButton {
  background-color: #fff;
  margin-top: -40px;
  padding-top: 46px;

  p {
    max-width: 90%;
    width: 600px;
    margin: 12px auto;
  }
  .btn-allies-assistance {
    width: 220px;
    margin: 10px auto;
  }
}

.new-down-button {
  display: block;
  border: 0px;
  height: 45px;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px;
  cursor: pointer;
  border-radius: 8px;
  background-color: #ED1C27;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;

  &:hover {
    background-color: #ef3e47;
  }
  svg {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    position: relative;
    top: -2px;
  }
}

.mc-page-content__master {
  position: relative;
  .float-scroll-down {
    position: absolute;
    left: 47%;
    right: 0px;
    width: 145px;
    bottom: -18px;
    cursor: pointer;
    z-index: 2;
  
    img {
      width: 100%;
      height: auto;
    }
  }
}
