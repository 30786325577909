  .SliderPlanSection {
    &.visible{
      // height: 100vh;
    }
    display: flex;
    flex-direction: column;
    padding: 88px 0;

    @media only screen and (max-width: 992px) {
      overflow: hidden;
    }

    .title {
      padding-left: 120px;

      @media (max-width: 992px) {
        padding-left: 0px;
      }


      h3 {
        color: var(--Contenidos, #131112);
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;
        margin: 0;
        margin-bottom: 40px;

        @media (max-width: 992px) {
          text-align: center;
        }

      }
    }

    .main-slider {
      padding-left: 90px;
      @media (max-width: 992px) {
        padding-left: 0px;
       
      }
      .slider-container {
        margin-bottom: 30px;
        @media (max-width: 992px) {
          padding-left: 0px;
         
        }

        .card-planSlider {
          height: 292px;
          display: flex !important;
          gap: 16px;
          border-radius: 12px;
          background: var(--Color-White-2, #F0F0F0);
          box-shadow: 0px 115px 32px 0px rgba(135, 135, 135, 0.00), 0px 74px 29px 0px rgba(135, 135, 135, 0.01), 0px 41px 25px 0px rgba(135, 135, 135, 0.05), 0px 18px 18px 0px rgba(135, 135, 135, 0.09), 0px 5px 10px 0px rgba(135, 135, 135, 0.10);

          @media (max-width: 992px) {
            flex-direction: column;
            align-items: center;
            height: auto;
          }

          .banner {
            border-radius: 4px 0px 0px 4px;
            margin: 0;

            img {
              border-radius: 4px 0px 0px 4px;
              // width: 100%;
              max-width: 292px;

              @media (max-width: 992px) {
                max-width: 100%;

              }
            }
          }

          .card-content-plan-slider {
            max-width: 700px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 8px;
            padding: 30px 0;

            @media (max-width: 992px) {
              height: 277px;
            }

            h4,
            h5,
            ul {
              margin: 0;
              padding: 0;
              list-style: none;
              text-align: left;
              max-width: 370px;
            }
            h5 {
              div {
                color: var(--Primario-Primario, #ED1C27);
                font-family: "Myriad Pro Semibold";
                font-size: 28px;
                font-style: normal;
                font-weight: 600;
                line-height: 32px;
              }
            }

            h4 {
              color: var(--Contenidos, #131112);
              font-family: 'Myriad Pro Semibold';
              font-size: 32px;
              font-style: normal;
              font-weight: 600;
              line-height: 36px;
            }

            h5 {
              color: var(--Primario-Primario, #ED1C27);
              font-family: 'Myriad Pro Semibold';
              font-size: 28px;
              font-style: normal;
              font-weight: 600;
              line-height: 32px;
              /* 114.286% */
            }

            ul {
              text-align: left;

              li {
                padding-bottom: 10px;
                display: flex;
                align-items: center;
                gap: 4px;

                .icon-slider-plan {
                  div.mc-icon{
                    width: 24px;
                    height: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    margin: 0 !important;
                  }
                  width: 24px;
                  height: 24px;
                  padding: 4px;
                  border-radius: 100px;
                  background: var(--Secundarios-Neutro-8, #F0F0F0);
                  box-shadow: 0px 11px 3px 0px rgba(0, 0, 0, 0.00), 0px 7px 3px 0px rgba(0, 0, 0, 0.01), 0px 4px 2px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 0px 1px 0px rgba(0, 0, 0, 0.10);
                }

                p {
                  text-align: left;
                  margin: 0;

                }
              }
            }
          }
        }
      }
    }

    .slick-initialized .slick-slide {
      div {
        margin: 0 15px 0 0;

        @media (max-width: 576px) {
          margin: 0 15px 0 -1px;
        }

      }
    }
    .custom-next, .custom-prev{
      position: absolute;
      bottom: -50px;
      padding: 0;
      margin: 0;
      border: none;
      outline: none;
      background: none;
      width: 32px;
      img{
        width: 100%;
        &:hover{
          box-shadow: 0px 0px 24px 0px rgba(201, 12, 20, 0.50) !important;
          background-color: transparent !important;
          border-radius: 100px;
          padding: 0;
        }
      }
    }

    .custom-prev{
      left: 0;
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      @media (max-width: 992px) {
       left: 40%;
      }
}
    .custom-next{
      left: 60px;
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      @media (max-width: 992px) {
       left: auto;
       right: 40%;
      }
}
  }