.SectionFaqrsMayorAward {
  padding-top: 40px;
  padding-bottom: 90px;
  background: linear-gradient(0deg, #FFF 0%, #F0F0F0 16.08%);
  article{
    background: url('../../../public//assets/mayor-award/airplane-left.png') no-repeat left 25% / 30%, 
    url('../../../public//assets/mayor-award/airplane-right.png') no-repeat top right / 20%;
    
    
    @media (max-width: 992px) {
      background: url('../../../public//assets/mayor-award/airplane-left.png') no-repeat left 25% / 30%, 
      url('../../../public//assets/mayor-award/airplane-right.png') no-repeat top -30% / 30%;
     
    }
  }
  .faq-content {
    position: sticky;
    top: 10%;
    padding-left: 100px;
    max-width: 282px;
    @media (max-width: 992px) {
      max-width: 100%;
      padding-left: 0px;
    }

    h3 {
      margin: 0;
      color: var(--Contenidos, #131112);
      font-family: 'Myriad Pro Semibold';
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
      margin-bottom: 32px;
      @media (max-width: 992px) {
        text-align: center;
        br{
          display: none;
        }
      }

    }

    p.faq-description {
      margin: 0;
      margin-bottom: 24px;
      color: var(--Contenidos, #131112);
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      text-align: left;
      position: relative;
      top: 10px;
      @media (max-width: 992px) {
        text-align: center;
      }

    }
  }

  .group-items {
    display: block;
    width: 142%;
    margin-left: -20%;
    @media (max-width: 992px) {
      flex-wrap: nowrap;
      flex-direction: column;
      width: 100%;
      margin-left: 0%;
    }

    .item {
      text-align: left;
      display: inline-block;
      vertical-align: top;
      width: 47%;
      border-radius: 12px;
      border: 1px solid var(--Secundarios-Neutro-7, #B8B6B7);
      background: var(--Secundarios-Neutro-9, #FFF);
      padding: 20px;
      margin: 8px;
      box-sizing: border-box;
      /* Shadow 3 */
      box-shadow: 0px 115px 32px 0px rgba(135, 135, 135, 0.00), 0px 74px 29px 0px rgba(135, 135, 135, 0.01), 0px 41px 25px 0px rgba(135, 135, 135, 0.05), 0px 18px 18px 0px rgba(135, 135, 135, 0.09), 0px 5px 10px 0px rgba(135, 135, 135, 0.10);

      @media (max-width: 992px) {
        width: 100%;
        margin: 0px;
        margin-bottom: 20px;
      }

      .mc-faqs-container {
        margin: 0px;
        .mc-accordeon-container {
          margin: 0px;
          border-bottom: 0px;
          
          .mc-accordeon-toggle {
            padding-right: 30px;
          }
        }
      }

      &:nth-child(even) {
        transform: translateY(32px);
        -webkit-transform: translateY(32px);
        -moz-transform: translateY(32px);
        -ms-transform: translateY(32px);
        -o-transform: translateY(32px);
        
        @media (max-width: 992px) {
          transform: translateY(0px);
          -webkit-transform: translateY(0px);
          -moz-transform: translateY(0px);
          -ms-transform: translateY(0px);
          -o-transform: translateY(0px);
}
      }

      &:nth-child(3) {
        transform: translate(0px, -20px);
        @media (max-width: 992px) {
          transform: translate(0px, 0px);
        }
      }
      &:nth-child(5) {
        transform: translate(0px, -20px);
        @media (max-width: 992px) {
          transform: translate(0px, 0px);
        }
      }

      h4 {
        margin: 0;
        color: var(--Contenidos, #131112);
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        margin-bottom: 20px;
      }

      p {
        color: var(--Secundarios-Neutro-4, #4D4D4D);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        margin: 0;
      }
    }
  }
}