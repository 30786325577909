.mc-page-terms {
  padding-bottom: 0px;

  .mc-terms-container {
    margin-top: -10px;
    margin-bottom: 70px;
  }

  .mc-static-content {
    margin-top: 50px;
    margin-bottom: 70px;

    p {
      font-size: 16px;
    }

    strong {
      font-weight: bold;

      span {
        font-weight: bold;
      }
    }
  }

  @media only screen and (min-width: 992px) {
    & > .mc-page-bg {
      margin-bottom: 35px;
      -webkit-border-bottom-right-radius: 35px;
      -webkit-border-bottom-left-radius: 35px;
      -moz-border-radius-bottomright: 35px;
      -moz-border-radius-bottomleft: 35px;
      border-bottom-right-radius: 35px;
      border-bottom-left-radius: 35px;
      overflow: hidden;

      .mc-app-rely {
        position: absolute;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      .mc-section {
        position: relative;
        z-index: 1;
        width: 1100px;
        max-width: 100%;
        box-sizing: border-box;
        padding: 0 18px;
        max-width: 90%;
        margin: auto;
        z-index: 2;
        left: 0px;
        right: 0px;
        top: -30px;
        bottom: 0px;

        h2 {
          text-align: left;
          margin: 10px 14px;
          font-size: 48px;
          font-style: normal;
          font-weight: 900;
          line-height: 50px;
          margin-top: 20px;
          width: 540px;
          color: #0F0F0F;
        }
        .mc-page-awards__available {
          text-align: left;
          margin: 10px 0px;
          font-size: 24px;
          font-style: normal;
          font-weight: 350;
          line-height: 28px;
          width: 470px;
          color: #0F0F0F;
        }
        & > .mc-app-container {
          max-width: 100%;
          padding: 0px;
        }

      }

      img {
        width: 100%;
        height: auto;
        margin-bottom: -10px;
      }
    }
  }

  h1 { color: #000; font-family:Calibri, sans-serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 14pt; }
  .s1 { color: #000; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt; }
  .p, p { color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; margin:0pt; }
  .s2 { color: #00F; font-family:Calibri, sans-serif; font-style: normal; font-weight: bold; text-decoration: underline; font-size: 10pt; }
  h2 { color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 10pt; }
  a { color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: underline; font-size: 10pt; }
  .s5 { color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; }
  li {display: block; }
  #l1 {padding-left: 0pt;counter-reset: c1 1; }
  #l1> li>*:first-child:before {counter-increment: c1; content: counter(c1, decimal)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 10pt; }
  #l1> li:first-child>*:first-child:before {counter-increment: c1 0;  }
  #l2 {padding-left: 0pt;counter-reset: c2 1; }
  #l2> li>*:first-child:before {counter-increment: c2; content: counter(c1, decimal)"."counter(c2, decimal)". "; color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
  #l2> li:first-child>*:first-child:before {counter-increment: c2 0;  }
  #l3 {padding-left: 0pt;counter-reset: d1 4; }
  #l3> li>*:first-child:before {counter-increment: d1; content: counter(d1, decimal)" "; color: black; font-style: normal; font-weight: normal; text-decoration: none; }
  #l3> li:first-child>*:first-child:before {counter-increment: d1 0;  }
  #l4 {padding-left: 0pt;counter-reset: d2 1; }
  #l4> li>*:first-child:before {counter-increment: d2; content: counter(d1, decimal)"."counter(d2, decimal)" "; color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; }
  #l4> li:first-child>*:first-child:before {counter-increment: d2 0;  }
  #l5 {padding-left: 0pt;counter-reset: c2 1; }
  #l5> li>*:first-child:before {counter-increment: c2; content: counter(c1, decimal)"."counter(c2, decimal)". "; color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
  #l5> li:first-child>*:first-child:before {counter-increment: c2 0;  }
  #l6 {padding-left: 0pt;counter-reset: c2 1; }
  #l6> li>*:first-child:before {counter-increment: c2; content: counter(c1, decimal)"."counter(c2, decimal)". "; color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
  #l6> li:first-child>*:first-child:before {counter-increment: c2 0;  }
  #l7 {padding-left: 0pt;counter-reset: c2 1; }
  #l7> li>*:first-child:before {counter-increment: c2; content: counter(c1, decimal)"."counter(c2, decimal)". "; color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
  #l7> li:first-child>*:first-child:before {counter-increment: c2 0;  }
  #l8 {padding-left: 0pt;counter-reset: c2 1; }
  #l8> li>*:first-child:before {counter-increment: c2; content: counter(c1, decimal)"."counter(c2, decimal)". "; color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
  #l8> li:first-child>*:first-child:before {counter-increment: c2 0;  }
  #l9 {padding-left: 0pt;counter-reset: c3 1; }
  #l9> li>*:first-child:before {counter-increment: c3; content: "("counter(c3, lower-roman)") "; color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; }
  #l9> li:first-child>*:first-child:before {counter-increment: c3 0;  }
  #l10 {padding-left: 0pt;counter-reset: c4 1; }
  #l10> li>*:first-child:before {counter-increment: c4; content: "("counter(c4, lower-latin)") "; color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; }
  #l10> li:first-child>*:first-child:before {counter-increment: c4 0;  }
  #l11 {padding-left: 0pt;counter-reset: c3 1; }
  #l11> li>*:first-child:before {counter-increment: c3; content: "("counter(c3, lower-roman)") "; color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; }
  #l11> li:first-child>*:first-child:before {counter-increment: c3 0;  }
  #l12 {padding-left: 0pt;counter-reset: c4 1; }
  #l12> li>*:first-child:before {counter-increment: c4; content: "("counter(c4, lower-latin)") "; color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; }
  #l12> li:first-child>*:first-child:before {counter-increment: c4 0;  }
  #l13 {padding-left: 0pt;counter-reset: e1 1; }
  #l13> li>*:first-child:before {counter-increment: e1; content: "("counter(e1, lower-roman)") "; color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; }
  #l13> li:first-child>*:first-child:before {counter-increment: e1 0;  }
  a {
    cursor: pointer;
  }
}