.mc-page {
  position: relative;
  padding-top: 0px;
  padding-bottom: 0px;

  min-height: 300px;
  background-position: top center;
  background-size: 100% auto;
  background-repeat: no-repeat;

  @media only screen and (min-width: 992px) {
    min-height: 600px;
  }

  &.dashboard {
    background-color: rgb(240, 240, 240);
    padding-top: 25px;

    .mc-app-container {
      width: 1110px;
      padding: 0 15px;
    }
  }

  &.terms,
  &.faqs {
    h1 {
      @media only screen and (max-width: 992px) {
        text-align: center;
      }
    }
  }

  &.awards,
  &.redemptions,
  &.alliances,
  &.terms,
  &.faqs {
    // background-color: $colorBg;
    background-color: #FFFFFF;
  }

  &.awards {
    .SectionPremioMayor {
      margin-top: 0px !important;
      border-radius: 0ñpx !important;
    }
  }
  &.awards {
    @media only screen and (max-width: 1500px) {
      background-size: 1500px auto;
    }
    @media only screen and (max-width: 992px) {
      background-size: 100%;
    }
  }
  &.alliances {
    .mc-page-content {
      @media only screen and (max-width: 1500px) {
        background-size: 1400px auto;
      }
      @media only screen and (max-width: 992px) {
        background-size: 100%;
      }
    }
  }

  &.faqs {
    margin-bottom: 20px;
  }

  .mc-static-content {
    margin: 30px 0;

    @media only screen and (min-width: 992px) {
      margin: 50px 0;
      margin-top: 50px !important;
    }

  }

  .mc-page-bg {
    background-color: $colorBg;
    padding: 2px 0px;
    padding-top: 50px;
  }

  .mc-page-container {
    -webkit-border-top-left-radius: 32px;
    -webkit-border-top-right-radius: 32px;
    -moz-border-radius-topleft: 32px;
    -moz-border-radius-topright: 32px;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    overflow: hidden;
    background-color: #fbf9f5;
    padding-top: 24px;
    padding-bottom: 30px;

    &__title {
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 20px;
      color: #1A1A1A;

      @media only screen and (min-width: 992px) {
        text-align: center;
        font-weight: 350;
        line-height: 24px;
      }
    }

    &__subtitle {
      font-size: 12px;
      font-style: normal;
      font-weight: 350;
      line-height: 14px;
      color: #0F0F0F;

      @media only screen and (min-width: 992px) {
        margin-top: 5px;
        text-align: center;
      }
    }

    @media only screen and (min-width: 992px) {
      background-color: transparent;
      padding-top: 0px;
    }
  }

  .mc-page-content {
    position: relative;
    background-attachment: fixed;
    background-position: top center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    padding-top: 125px;

    h1 {
      color: #131112;
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    h2 {
      color: #131112;
      text-align: center;
      font-family: "Myriad Pro";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }

    p {
      color: #131112;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }

    &__separator {
      width: 190px;
      margin: 16px auto;
      z-index: 1;

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    &__benefits {
      position: relative;
      margin-top: 70px;
      padding-bottom: 130px;

      @media only screen and (max-width: 992px) {
        padding-bottom: 30px;
        text-align: center;

        h2 {
          display: none;
        }
        .mc-app-columns__item {
          h3 {
            display: block !important;
            color: var(--Contenidos, #131112);
            text-align: center;
            font-family: "Myriad Pro";
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
          }
        }
        
      }

      p {
        margin-bottom: 30px;
      }

      h3 {
        display: none;
      }

      @media only screen and (min-width: 992px) {
        margin-top: 92px;
        padding-bottom: 50px;
        
        &>h2 {
          display: none;
        }

        h3 {
          display: block;
        }

        .mc-app-columns {
          .mc-app-columns__item {
            box-sizing: border-box;

            &:nth-child(1) {
              .mc-app-centry {
                padding-right: 16px;
                text-align: right;

                img {
                  width: 80%;
                }
              }
            }

            &:nth-child(2) {
              .mc-app-centry {
                padding-left: 30px;
                padding-right: 130px;

                h3 {
                  text-align: left;
                  color: var(--Contenidos, #131112);
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 28px;
                }

                p {
                  color: var(--Color-Black-1, #0F0F0F);
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                  text-align: left;
                  margin-bottom: 40px;
                }

                &>div {
                  display: inline-block;
                }

                .mc-button-container {
                  display: inline-block;

                  button {
                    padding-left: 20px;
                    padding-right: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__master {
      position: relative;

      img {
        width: 90%;
        height: auto;
      }

      @media only screen and (min-width: 992px) {
        margin-bottom: 50px;
        text-align: right;

        .mc-page-content__inner {
          position: absolute;
          left: 5%;
          top: 6%;
          bottom: 0px;
          width: 380px;

          .mc-app-centry {
            p {
              padding-right: 30px;

              &.bittext {
                font-size: 18px;
                margin-bottom: 4px;
              }

              div {
                font-size: 15px;
                text-decoration: underline;
              }
              a {
                color: #0F0F0F;
              }
            }
          }

          h1,
          p {
            text-align: left;
          }

          .mc-page-content__separator {
            margin-left: 0px;
          }

        }
      }
    }

    &__info {
      position: relative;
      background-color: #fff;
      border-radius: 20px;
      box-sizing: border-box;
      padding: 20px;
      padding-bottom: 30px;
      margin-top: 25px;

      @media only screen and (min-width: 992px) {
        width: 956px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 0px;
        background-color: transparent;
        height: 530px;
        position: relative;
      }

      h2 {
        color: #ED1C27;
        font-family: 'Myriad Pro Bold';
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 900;
        line-height: 32px;
        margin: 0px;
        margin-top: 10px;

        @media only screen and (min-width: 992px) {
          position: absolute;
          left: 0px;
          top: 0px;
          margin-top: 0px;
          width: 230px;
          text-align: left;
        }
      }

      &>p {
        font-family: 'Myriad Pro Black';
        color: #131112;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        margin-top: 2px;
        margin-bottom: 30px;

        @media only screen and (min-width: 992px) {
          position: absolute;
          left: 0px;
          top: 10px;
          margin-top: 0px;
          width: 230px;
          text-align: left;

          &.date-update-info {
            top: 106px;
            font-family: 'Myriad Pro';
            font-weight: 400;

            strong {
              display: block;
              font-family: 'Myriad Pro Black';
            }
          }
        }
      }
    }

    .mc-page-content__barInfo-container {
      height: 124px;
      position: relative;
      margin-left: 245px;

      &.is-goal-done {
        filter: grayscale(100%) !important;
        -webkit-filter: grayscale(100%) !important;
        -moz-filter: grayscale(100%) !important;

        .mc-page-content__barInfo--head {
          opacity: 0.6;
        }
      }

      &.complete-goal {
        .mc-page-content__barInfo {
          border: 2px solid var(--Primario-Primario-oscuro, #C90C14);
        }
      }

      @media only screen and (max-width: 992px) {
        margin-left: 0px;
        height: auto;
      }
      @media only screen and (min-width: 992px) {
        left: 72px;

        &::after {
          content: "";
          position: absolute;
          right: 0px;
          top: 0px;
          height: 1px;
          background-color: #c7c7c7;
          top: -5px;
          left: 178px;
          z-index: 1;
        }
      }

      &.second {
        margin-top: 120px;

        @media only screen and (max-width: 992px) {
          margin-top: 40px;
        }
        @media only screen and (min-width: 992px) {
          &::after {
            left: 188px;
          }
        }
      }

      & > span {
        color: var(--Secundarios-Neutro-4, #4D4D4D);
        font-family: "Myriad Pro";
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        position: relative;
        top: -20px;
        @media only screen and (min-width: 992px) {
          left: 19px;
        }

        @media only screen and (max-width: 992px) {
          top: 0px;
        }
      }
    }

    &__barInfo {
      position: relative;
      height: 115px;
      border-radius: 16px;
      border: 1px solid #B8B6B7;
      box-sizing: border-box;
      padding: 14px 15px;
      margin: 15px 0px;

      &--head {
        position: relative;
        height: 20px;
        margin-bottom: 9px;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 900;
        line-height: 22px;
        font-family: 'Myriad Pro Bold';
        display: inline-block;
        border-radius: 20px;
        background: #C90C14;
        box-shadow: 0px 0px 8px 0px #C90C14;
        padding: 4px 14px;
        padding-top: 2px;
        margin-bottom: 7px;

        .mc-icon {
          display: inline-block;
          vertical-align: middle;
          position: relative;
          top: -1px;
          margin-right: 2px;

          svg {
            g {
              path {
                fill: #fff;
              }
            }
          }
        }

        &.yellow {
          border-radius: 20px;
          background: #F8991D;
          box-shadow: 0px 0px 8px 0px var(--Terciarios-Naranja-2, #E97D00);
          color: #131112;

          .mc-icon {
            svg {
              g {
                path {
                  fill: #131112;
                }
              }
            }
          }
        }

      }

      &--row {
        position: relative;
        height: 20px;
        margin-bottom: 18px;
        margin-left: 2px;

        span {
          color: #131112;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;

          strong {
            color: #4D4D4D;
            text-align: center;
            font-weight: 900;
            font-family: 'Myriad Pro Bold';
          }

          &:nth-child(1) {}

          &:nth-child(2) {
            position: absolute;
            right: 0px;
            top: 0px;
          }
        }
      }

      &.disabled {
        // filter: grayscale(100%);
        // -webkit-filter: grayscale(100%);
        // -moz-filter: grayscale(100%);

        .mc-page-content__barInfo--head {
          border-radius: 20px;
          background: var(--Secundarios-Neutro-7, #B8B6B7);
          box-shadow: 0px 0px 8px 0px #B8B6B7;
        }
      }

      @media only screen and (min-width: 992px) {
        margin-top: 0px;

        &:nth-of-type(1) {
          position: absolute;
          top: 20px;
          width: 320px;
          height: 114px;
          z-index: 1;
          background-color: #fff;
          right: 334px;
        }

        &:nth-of-type(2) {
          position: absolute;
          top: 20px;
          width: 320px;
          height: 114px;
          z-index: 1;
          background-color: #fff;
          right: 0px;
        }
      }
    }

    &__dateupdate {
      color: #131112;
      text-align: center;
      font-family: "Myriad Pro";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin: 25px 0px;
      display: none;

      @media only screen and (min-width: 992px) {
        position: absolute;
        bottom: 10px;
        right: 0;
        left: 354px;
        text-align: left;
        margin-bottom: 16px;
      }

      &.before {
        display: none;
        @media only screen and (min-width: 992px) {
          left: 19px;
          bottom: -34px;
          margin: 0;
          opacity: 0.8;
        }
      }
    }

    &__middleInfo {
      position: absolute;
      left: 358px;
      margin-top: 28px;
      right: -48px;
      height: 45px;

      @media only screen and (max-width: 992px) {
        position: relative;
        left: 0px;
        right: auto;
        text-align: center;
        height: auto;
      }

      & > span {
        color: var(--Primario-Primario, #ED1C27);
        font-family: "Myriad Pro";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        position: relative;
        top: 10px;
      }

      .mc-page-content__ctas {
        position: absolute;
        right: 0px;
        top: 0px;
        margin-top: 0px;
        margin-right: 0px;

        @media only screen and (max-width: 992px) {
          position: relative;
          margin-top: 26px;
        }
      }
    }

    &__ctas {
      @media only screen and (min-width: 992px) {
        float: right;
        width: 160px;
        margin-right: -71px;
        margin-top: 30px;
      }
    }

    &__redemptionsOnAwards {
      position: relative;
      margin: 78px auto;
      max-width: 90%;

      @media only screen and (min-width: 992px) {
        margin-bottom: 0px;
      }
      @media only screen and (max-width: 992px) {
        background-color: #fff;
        max-width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 115px;
      }

      h3 {
        color: var(--Contenidos, #131112);
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 10px;
      }

      p {
        color: var(--Contenidos, #131112);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin-top: 10px;
      }

      &--awards {
        min-height: 195px;
        height: 195px;
        text-align: center;
        position: relative;

        &.empty {

        }

        .mc-awards-container {
          margin-top: 0px !important;
        }

      }

      .mc-page-content__redemptionsOnAwards--labels {
        p {
          max-width: 275px;
          margin: auto;
        }
      }

      @media only screen and (min-width: 992px) {
        // background-color: #fff;
        // border-radius: var(--L, 24px);
        text-align: center;
        padding: 60px 0;
        max-width: 100%;

        .mc-page-content__redemptionsOnAwards--labels {
          display: inline-block;
          vertical-align: top;
          width: 300px;
          margin-top: 50px;

          h3,
          p {
            text-align: left;
            padding-right: 30px;
          }

          h3 {
            margin-top: 46px;
          }
        }

        .mc-page-content__redemptionsOnAwards--awards {
          display: inline-block;
          vertical-align: top;
          width: 392px;
          margin-top: 30px;
        }
      }
    }


    @media only screen and (min-width: 992px) {
      min-height: 600px;

      .mc-page-content__ctas {
     
      }
    }

    &__primary {
      &--customDots {
        display: none;
      }

      @media only screen and (min-width: 992px) {
        position: relative;
        height: 354px;
        margin-top: 20px;

        .mc-app-rely {
          width: 350px;

          h1,
          p {
            text-align: left;
          }

          .mc-page-content__separator {
            margin-left: 0px;
          }
        }

        // .mc-slider-rotator {
        //   position: absolute;
        //   top: 0;
        //   right: 0;
        //   left: 386px;
        //   margin-top: 0 !important;
        //   bottom: 0px;
        //   height: auto;
        //   width: auto;
        //   overflow: visible;
        //   .slick-slider {
        //     height: 100%;
        //     overflow: visible;
        //     .slick-list {
        //       height: 100%;
        //       overflow: visible;
        //       div.slick-track {
        //         height: 100%;
        //         width: 1020px !important;
        //         overflow: visible;
        //         position: absolute;
        //         left: 0px;
        //         top: 0px;
        //         -webkit-transition: all .5s cubic-bezier(.77,0,.175,1);
        //         -moz-transition: all .5s cubic-bezier(.77,0,.175,1);
        //         -o-transition: all .5s cubic-bezier(.77,0,.175,1);
        //         transition: all .5s cubic-bezier(.77,0,.175,1);
        //         div.slick-slide {
        //           height: 100% !important;
        //           width: 340px !important;
        //           float: left;

        //           .mc-slider-rotator__slide {
        //             padding: 0 0px;
        //             position: relative;

        //             &:after {
        //               content: '';
        //               position: absolute;
        //               left: 0px;
        //               bottom: 0px;
        //               z-index: 1;
        //               width: 45%;
        //               height: 10px;
        //               background-color: #C90C14;
        //             }
        //             .mc-cardbox-container {
        //               background: linear-gradient(180deg, rgba(255, 255, 255, 0.70) 0%, rgba(230, 230, 230, 0.70) 100%);
        //               & > div {
        //                 height: 75px;
        //               }

        //               h2, div {
        //                 -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
        //                 -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
        //                 -o-transition: all .3s cubic-bezier(.77,0,.175,1);
        //                 transition: all .3s cubic-bezier(.77,0,.175,1);
        //               }
        //             }
        //           }
        //         }
        //       }
        //     }
        //   }

        //   &.step-1 {
        //     div.slick-track {
        //       .slick-slide {
        //         &:nth-child(3) {
        //           opacity: 0;
        //           transform: scale(1.1) translate(13px, 0px);

        //           .mc-cardbox-container {
        //             h2, div {
        //               opacity: 0;
        //             }
        //           }
        //         }
        //       }

        //     }
        //   }

        //   &.step-2 {
        //     div.slick-track {
        //       left: -342px !important;
        //       .slick-slide {
        //         &:nth-child(1) {
        //           opacity: 0;
        //           transform: scale(0.4);
        //         }
        //       }

        //     }
        //   }
        // }

        .mc-page-content__primary--customDots {
          display: block;
          position: absolute;
          right: -64px;
          top: 47%;
          border: 0;
          width: 45px;
          height: 45px;
          margin: auto;
          float: none;

          &--buttonLeft {
            position: absolute;
            left: 6px;
            width: 18px;
            height: 18px;
            background-image: url('../../../public/assets/images/arrow-L-white.png');
            background-position: center center !important;
            background-repeat: no-repeat !important;
            background-size: 22% !important;
            border: 0px;
            background-color: transparent;
            cursor: pointer;
            top: 3px;
            display: none;
            transform: rotate(180deg);

            &:hover {
              opacity: 0.9;
            }
          }

          &--buttonRight {
            position: absolute;
            right: 6px;
            width: 18px;
            height: 18px;
            background-image: url('../../../public/assets/images/arrow-L-white.png');
            background-position: center center !important;
            background-repeat: no-repeat !important;
            background-size: 22% !important;
            border: 0px;
            background-color: transparent;
            cursor: pointer;
            top: 3px;
            display: none;

            &:hover {
              opacity: 0.9;
            }
          }

          &--steps {
            position: absolute;
            height: 18px;
            left: 24px;
            right: 24px;
            display: none;

            &__step {
              width: 10px;
              height: 10px;
              background-color: gray;
              border-radius: 50px;
              margin: 0 4px;
              -webkit-transition: all .3s cubic-bezier(.77, 0, .175, 1);
              -moz-transition: all .3s cubic-bezier(.77, 0, .175, 1);
              -o-transition: all .3s cubic-bezier(.77, 0, .175, 1);
              transition: all .3s cubic-bezier(.77, 0, .175, 1);
              position: relative;
              display: inline-block;
              vertical-align: middle;
              margin-top: 0px;
              cursor: pointer;

              &.active {
                background-color: $primaryColor;
                width: 36px;
                height: 10px;
              }
            }
          }

          .mc-page-content__primary--customDots--buttonLeft,
          .mc-page-content__primary--customDots--buttonRight {
            left: 0;
            background-color: #ED1C27;
            width: 100%;
            height: 100%;
            margin: 0;
            top: 0;
            border-radius: 100px;
            color: #fff;
            cursor: pointer;
          }

          &.step-1 {
            .mc-page-content__primary--customDots--buttonRight {
              display: block;
            }
          }

          &.step-2 {
            .mc-page-content__primary--customDots--buttonLeft {
              display: block;
            }
          }
        }
      }
    }

    &__secondary {
      @media only screen and (min-width: 992px) {
        margin: 0 auto;
        margin-top: 56px;
        width: 475px;
        margin-bottom: 90px;

        .mc-page-content__separator {
          display: none;
        }

        .mc-button-container {
          width: 180px;
          margin: 0 auto;
          margin-top: 26px;
        }
      }
    }
  }
  &.alliances{
    background-color: #fff !important;
    .mc-page-content{
      min-height: 520px;

    }
  }

  &.mechanic {
    .SectionPremioMayor {
      @media only screen and (min-width: 992px) {
        padding: 50px 0 var(--8xl, 88px) 0;
        margin-bottom: 20px;
        padding-bottom: 20px;
      }
    }
    .mc-page-content {
      min-height: 520px;
      @media only screen and (min-width: 992px) {
        overflow: hidden;

        .mc-page-content__ctas {
          position: relative;
          margin: 0 auto;
          float: none;
          width: auto;
        }
      }

      @media only screen and (max-width: 992px) {
        .mc-page-content__ctas {
          .mc-button-container {
            button {
              width: 100%;
            } 
          }
        }
      }

    }

    .mc-page-content__secondary {
      @media only screen and (min-width: 992px) {
        margin-bottom: 0px;
      }
    }
    .mc-page-content__ctas {
      @media only screen and (min-width: 992px) {
        top: 0;
      }
    }

    .SectionPremioMayor {
      margin-top: 0px;

      @media only screen and (max-width: 992px) {
        border-radius: 0px;
      } 
    }
  }

  &.terms,
  &.faqs {
    background-color: #fff;
    position: relative;
    z-index: 0;

    .mc-page-content.absolute {
      position: relative;
      padding-top: 0px;

      @media only screen and (min-width: 992px) {
        min-height: 40px;
      }

      .mc-page-content__absolute {
        position: absolute;
        left: 0px;
        top: 65px;
        right: 0px;
        bottom: 0px;
        z-index: 1;

        .mc-app-container {
          height: 100%;

          .mc-page-content__separator {
            margin-bottom: 0px;
          }
        }
      }

      &>img {
        width: 100%;
        height: auto;
      }
    }

    .mc-page-container {
      background: #fff;
      border-radius: 0px;
    }
  }
}

.line-title {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: -16px;
    left: 0;
    width: 273px;
    height: 2px;
    background: linear-gradient(90deg, rgba(237, 28, 39, 0.00) 0%, #ED1C27 50%, rgba(237, 28, 39, 0.00) 100%);
  }

  &-center-lg {
    &::before {
      @media (max-width: 992px) {
        content: '';
        width: 190px;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
      }
    }
  }
}


.mc-page {
  &.progress {
    .SectionPremioMayor {
      margin-top: 0px;
      border-radius: 0px;
      -webkit-border-bottom-right-radius: 24px;
      -webkit-border-bottom-left-radius: 24px;
      -moz-border-radius-bottomright: 24px;
      -moz-border-radius-bottomleft: 24px;
      border-bottom-right-radius: 24px;
      border-bottom-left-radius: 24px;
    }
  }
}

.no-mobile {
  @media (max-width: 992px) {
    display: none !important;
  }
}

.no-desktop {
  @media (min-width: 992px) {
    display: none !important;
  }
}

.desktop-styles {
  @media (max-width: 992px) {
   margin-top: 46px;
   text-align: center;

   .mc-stair-slider__carousel--labels__tooltip,
   .mc-stair-slider__carousel--labels__cta {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
   }
  }
}

.mc-toast-container {
  position: fixed;
  right: 26px;
  bottom: 50px;
  z-index: 11;
  border-radius: 16px;
  border: 1px solid var(--Aciertos, #009088);
  background: var(--Secundarios-Neutro-9, #FFF);
  box-shadow: 0px 4px 6px 0px rgba(77, 77, 77, 0.30);
  padding: 12px;
  padding-left: 28px;
  width: 314px;
  height: 48px;

  &.isTopBar {
    position: relative;
    width: 100%;
    height: 54px;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: auto;
    border-radius: 0px;
    border-bottom: 1px solid var(--Secundarios-Neutro-7, #B8B6B7);
    background: var(--Secundarios-Neutro-4, #4D4D4D);
    box-shadow: none;
    padding: 0px;
    text-align: center;
    border: 0px;

    &:before {
      display: none;
    }

    span {
      color: #fff;
      position: relative;
      left: auto;
      top: auto;
      bottom: auto;
      right: auto;
      display: inline-block;
      vertical-align: middle;
      top: 1px;

      @media only screen and (max-width: 992px) {
        position: absolute;
        left: 14px;
        right: 140px;
        text-align: left;
        top: 4px;
      }
    }

    .mc-button-container {
      position: relative;
      top: auto;
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
      right: auto;
      left: auto;
      margin-top: 8px;
      top: -2px;

      @media only screen and (max-width: 992px) {
        position: absolute;
        right: 38px;
      }
      button {
        height: 40px;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    right: 10px;
    left: 10px;
    width: auto;
    bottom: 100px;
    z-index: 11;
  }

  & > span {
    position: absolute;
    left: 30px;
    top: 12px;
    right: 118px;

    color: var(--Contenidos, #131112);
    font-family: "Myriad Pro Bold";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;

    @media only screen and (max-width: 992px) {
      right: 142px;
    }
  }

  .mc-button-container {
    position: absolute;
    width: 80px;
    right: 42px;
    top: 14px;
  }

  .close-butt {
    position: absolute;
    right: 14px;
    top: 18px;
    z-index: 1;
    cursor: pointer;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 18px;
    height: 100%;
    z-index: 1;
    border-radius: var(--m, 16px) 0px 0px var(--m, 16px);
    background: var(--Aciertos, #009088);
  }
}

.progress-banner {
  width: 564px !important;

  @media only screen and (max-width: 992px) {
    width: 100% !important;
  }
}
.awards-banner {
  width: 683px !important;

  @media only screen and (max-width: 992px) {
    width: 100% !important;
  }
}
.alliances-banner {
  width: 600px !important;

  @media only screen and (max-width: 992px) {
    width: 100% !important;
  }
}
.inner-progress {
  width: 396px !important;
  top: 14% !important;

  @media only screen and (max-width: 992px) {
    width: 100% !important;
  }
}

.mc-page-awards {
  .mc-page-content {
    @media only screen and (min-width: 992px) {
      min-height: 540px
    }
  }
  .sectionAwards {
    background-color: #fff !important;

    .mc-app-container {
      @media only screen and (min-width: 992px) {
        margin-top: -50px;
      }
      .mc-awards-container {
        padding-top: 50px;
      }
    }
  }
  .sectionRedemptions {

    &.lg-1 {
      @media only screen and (max-width: 992px) {
        height: auto;
        padding-left: 14px;
        padding-right: 14px;
        box-sizing: border-box;
      }
      .mc-page-content__redemptionsOnAwards--awards  {
        width: 440px;
        margin-top: 40px;

        @media only screen and (max-width: 992px) {
          width: 100%;
          margin-top: 10px;
        }
        .mc-awards-container__items--item {
          width: 80%;

          @media only screen and (max-width: 992px) {
            width: 100%;
          }
        }
      }
    }
    &.lg-2 {
      @media only screen and (max-width: 992px) {
        height: auto;
        padding-left: 14px;
        padding-right: 14px;
        box-sizing: border-box;
      }
      .mc-page-content__redemptionsOnAwards--awards  {
        margin-top: 40px;
        width: 700px;

        @media only screen and (max-width: 992px) {
          width: 100%;
          margin-top: 10px;
        }
      }
    }
    @media only screen and (min-width: 992px) {

    }
  }
}


.mc-header-container-page-progreso {
  .mc-header-container__nav {
    @media only screen and (min-width: 992px) {
      right: 0px !important;
    }
  }
}

.mc-header-container__nav {
  @media only screen and (max-width: 992px) {
    .mc-header-container__nav--item.item-alianzas {
      display: none !important;
    }
  }
  
}

.mc-toast-davipuntos {
  position: fixed;
  right: 20px;
  bottom: 5%;
  z-index: 2;
  width: 300px;
  display: none;

  @media only screen and (min-width: 992px) {
    z-index: 9;
  }

  @media only screen and (max-width: 992px) {
    left: 0px;
    right: 0px;
    margin: auto;
    bottom: 80px;
  }

  &.show {
    display: block;
  }

  img {
    width: 100%;
    height: auto;
  }

  div {
    position: absolute;
    width: 30px;
    height: 30px;
    z-index: 2;
    right: 19px;
    top: 24px;
    background-color: transparent;
  }
}

.mc-page.alliances {
  .mc-page-content__master {
    .mc-page-content__inner {
      @media only screen and (min-width: 992px) {
        bottom: 45px;
      }
    }
  }
}


.disclaimer-global {
  position: absolute;
  right: 22px;
  top: 113px;
  width: 10px;
  z-index: 5;

  &.minimal {
    top: 110px;
    width: 8px;
  }

  @media only screen and (max-width: 992px) {
    display: none;
  }

  svg {
    width: 100%;
    height: auto;
  }

  &.invert-image {
    -webkit-filter: invert(200%);
    filter: invert(200%);
    filter: brightness(200%);
    -webkit-filter: brightness(200%);
    -moz-filter: brightness(200%);
  }
}

.hoverArrows{
  svg:hover{
    box-shadow: 0px 0px 24px 0px rgba(201, 12, 20, 0.50) !important;
    background-color: transparent !important;
    border-radius: 100px;
    padding: 0;
  }
  img:hover{
    box-shadow: 0px 0px 24px 0px rgba(201, 12, 20, 0.50) !important;
    background-color: transparent !important;
    border-radius: 100px;
    padding: 0;
  }
}
.hoverArrowsImg{

  &:hover{
    box-shadow: 0px 0px 24px 0px rgba(201, 12, 20, 0.50) !important;
    background-color: transparent !important;
    border-radius: 100px;
    padding: 0;
  }
}